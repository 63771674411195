import styled from "styled-components";
import { GREY, ORANGE, LIGHT_GREY } from "config/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  overflow: auto;
  min-height: min-content;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 25%;
  box-sizing: border-box;
  min-height: min-content;

  @media screen and (max-width: 360px) {
    width: 100%;
    padding: 16px;
  }

  @media screen and (max-width: 414px) {
    width: 100%;
    padding: 16px;
  }

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 1080px) {
    width: 100%;
    padding: 16px;
  }
`;

export const Stars = styled.div`
  display: flex;
  display: -webkit-flexbox;
`;

export const Star = styled.div`
  border: solid 1px ${({ selected }) => (selected ? ORANGE : GREY)};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 3px 48px 3px;
  width: 20px;
  height: 20px;
  padding: 5px;
`;

export const StarIcon = styled.img`
  max-height: 100%;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Comment = styled.textarea`
  background-color: ${LIGHT_GREY};
  color: ${GREY};
  border-radius: 4px;
  border: none;
  resize: none;
  padding: 12px;
  height: 130px;
  margin-top: 16px;
  font-size: 16px;
  font-family: Helvetica Neue;
  width: 100%;
  box-sizing: border-box;

  ::placeholder {
    color: ${GREY};
  }

  &:focus {
    outline: none;
  }
`;

export const SendButton = styled.div`
  width: 50%;
  align-self: center;
`;
