import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Header, Label, Button } from "components/core";
import { GREY } from "config/colors";
import { changeLanguage } from "config/i18n";
import icStarFilled from "assets/images/star-filled.svg";
import icStarOutlined from "assets/images/star-outlined.svg";
import * as S from "./styles";

const Home = ({ onSubmit, application, applicationLanguage, isLoading }) => {
  const [hoveredStar, setHoveredStar] = useState(null);
  const { t: translate } = useTranslation();

  useEffect(() => {
    changeLanguage(applicationLanguage);
  }, []);

  const schemaValidation = yup.object().shape({
    star: yup.number().required(),
    comment: yup.string(),
  });

  return (
    <S.Container>
      <Helmet>
        <meta
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          name="viewport"
        />
      </Helmet>
      <Header />
      <S.Content>
        <Label fontWeight="bold" fontSize={20}>
          {translate("form-question")(application)}
        </Label>
        <Formik
          schemaValidation={schemaValidation}
          initialValues={{
            star: null,
            comment: "",
          }}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <S.Form onSubmit={handleSubmit}>
              <S.Stars onMouseOut={() => setHoveredStar(null)} onBlur={() => setHoveredStar(null)}>
                {Array.from({ length: 5 }).map((_, index) => {
                  const isSelected = index <= values.star && values.star !== null;
                  const isHovered = index <= hoveredStar && hoveredStar !== null;

                  return (
                    <S.Star
                      key={index}
                      selected={isSelected || isHovered}
                      onClick={() =>
                        handleChange({
                          target: {
                            value: index,
                            name: "star",
                          },
                        })
                      }
                      onMouseOver={() => setHoveredStar(index)}
                      onFocus={() => setHoveredStar(index)}
                    >
                      <S.StarIcon src={isSelected || isHovered ? icStarFilled : icStarOutlined} />
                    </S.Star>
                  );
                })}
              </S.Stars>
              <Label fontWeight="bold" fontSize={20}>
                {translate("form-tell-us")}
              </Label>
              <Label color={GREY} marginTop={12}>
                {translate("form-tell-us-subtitle")}
              </Label>
              <S.Comment
                name="comment"
                onChange={handleChange}
                placeholder={translate("type-here")}
                value={values.comment}
              />
              <S.SendButton>
                <Button
                  label={translate("send")}
                  marginTop={60}
                  type="submit"
                  disabled={values.star === null}
                  isLoading={isLoading}
                />
              </S.SendButton>
            </S.Form>
          )}
        </Formik>
      </S.Content>
    </S.Container>
  );
};

Home.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  application: PropTypes.string.isRequired,
  applicationLanguage: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

Home.defaultProps = {
  isLoading: false,
};

export default Home;
