import React, { useState, useEffect } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { navigate } from "gatsby";
import jwtDecryptor from "helpers/jwtDecryptor";
import HomePresentational from "components/presentations/Home";
import { GlobalStyle } from "components/core";
import { api, constants } from "utils";

const Home = () => {
  const [geolocation, setGeolocation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [token] = useQueryParam("token", StringParam);
  const payload = jwtDecryptor(token);

  if (!payload) {
    return null;
  }

  const submit = ({ star, comment }) => {
    setIsLoading(true);
    api
      .post(constants.urls.FEEDBACK, {
        ...payload,
        ...geolocation,
        stars: star + 1,
        comment: comment.length === 0 ? null : comment,
        application: payload.applicationName,
      })
      .then(() => {
        setIsLoading(true);
        navigate("/success");
      })
      .catch(() => {
        setIsLoading(false);
        navigate("/error");
      });
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      setGeolocation({
        latitude: payload.latitude || coords.latitude,
        longitude: payload.longitude || coords.longitude,
      });
    });
  }, []);

  return (
    <>
      <GlobalStyle />
      <HomePresentational
        onSubmit={submit}
        application={payload.applicationName}
        applicationLanguage={payload.appLanguage}
        isLoading={isLoading}
      />
    </>
  );
};

export default Home;
